import React, {useEffect} from 'react';
import LoginPage from './LoginPage';
import Contact from './Contact';
import UserHome from './UserHome'
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
  import { useSelector, useDispatch } from "react-redux";
  import AuthApi from "../apiCalls/authApi";
  import { setIsAuthenticated, setToken, setMeObject, setIsLoading, resetUserData } from '../store/actions/UserActions';
  import LeftNavbar from "../components/leftNavbar/LeftNavbar";
  import { useTranslation } from "react-i18next";
  import Navbar from "../components/bottomNavbar/Navbar";
  import Loading from "../components/loading/loading";
import SingUp from './SignUp';
import PrepareOrder from './PrepareOrder';
import PrivacyPolicy from '../PrivacyPolicy';
import Terms from '../Terms';
import Aggregator from './Aggregator';
import AggregatorProduct from './AggregatorProduct';
import MyOrder from './MyOrder';
import DetailedOrder from './DetailedOrder';

const Home = () => {
    const { t } = useTranslation();
    const isAuth = useSelector((state)=> state.userReducer.isAuthenticated);
    const isFullScreen = useSelector((state)=> state.userReducer.isFullScreen);
    const {getMeObject} = AuthApi();
    const isLoading = useSelector((state)=> state.userReducer.isLoading);
    const dispatch = useDispatch();
    // const locationOptions = useMemo(()=>convertObjToCityState(location),[])

    useEffect(()=>{
        const authenticate = async()=>{
          const token = localStorage.getItem('token');
          const userName = localStorage.getItem('user');
          if(token && !isAuth){
            const meObject = await getMeObject({token});
            if(meObject){
                dispatch(setMeObject(meObject));
                dispatch(setIsAuthenticated(userName));
                dispatch(setToken(token));
                dispatch(setIsLoading(false));
            }else{
                handleLogout()
            }
          }
        }
        authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLogout =() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      dispatch(resetUserData(""));
      dispatch(setIsAuthenticated(false));
    }


  return (
    <div>
      {isLoading? <Loading bgopacity={isLoading}/> : ""}
     <Router>
     {!isFullScreen?<> 
          <div className="top-0 z-20 bg-white fixed px-2" style={{display:'flex', width:'100%'}}>
              <LeftNavbar handleLogout={handleLogout}></LeftNavbar>
              {/* <div style={{margin: 'auto 10px auto auto', width:'100%'}}>
                <Select onChange={(e)=>dispatch(updateLocation(e.value))}  options={locationOptions} placeholder="Select City"/>
              </div> */}
              <div style={{margin: 'auto 10px auto auto'}}>
              {t('hi', {name: isAuth? isAuth?.toUpperCase():'Guest'})}
              </div>
          </div> 

          <div className="z-1 bg-white relative p-2" style={{display:'flex', width:'100%', height:'2.5em'}}>
          </div> 
        </>:null}
        <Routes>
            <Route path='/' element={isAuth?<UserHome/>:<LoginPage/>}></Route>
            <Route path='/login' element={<LoginPage/>}></Route>
            <Route path='/signup' element={isAuth?<UserHome/>:<SingUp/>}></Route>
            <Route path='/aggregator/:prod' element={isAuth?<AggregatorProduct/>:<SingUp/>}></Route>
            <Route path='/aggregator' element={isAuth?<Aggregator/>:<SingUp/>}></Route>
            <Route path='/orders' element={isAuth?<MyOrder/>:<SingUp/>}></Route>
            <Route path='/orders/:orderId' element={isAuth?<DetailedOrder/>:<SingUp/>}></Route>
            <Route path="/product/:itemId" element={isAuth? <PrepareOrder/> : <LoginPage/>}></Route>

            <Route path='/contact' element={<Contact/>}></Route>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
            <Route path="/Terms" element={<Terms/>}/>
        </Routes>
        { !isFullScreen ?   <Navbar></Navbar>:null}
    </Router>   
    </div>
  )
}

export default Home