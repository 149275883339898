import React from 'react'

function Button(props) {
    const text = props.text;
    const type = props.type || 'default';
    const onClick = props.onClick;
    const imageLogo = props.logo;
    const isDisabled = props.isDisabled;
    const notificationCount = props.notificationCount;
    const buttonVaraiation = () => {
        switch (type) {
            case 'default':
                return  <button style={{fontWeight:400, fontSize:'14px'}} disabled={isDisabled} onClick={onClick} type="button" className={`text-black ${isDisabled?'bg-gray-300':'bg-blue-400'} hover:bg-white-500 focus:outline-none focus:ring-4 focus:ring-grey-300 font-medium rounded-full text-sm px-5 py-2.5 text-center subText`}>{text}</button>
            
            case 'grey':
                return  <button disabled={isDisabled} onClick={onClick} type="button" className={`text-black ${isDisabled?'bg-gray-300':'bg-orange-400'} hover:bg-orange-500 focus:outline-none focus:ring-4 focus:ring-orange-300 font-medium rounded-full text-sm px-5 py-2.5 text-center subText`}>{text}</button>
                

            case 'small':
                return  <button style={{fontWeight:600, fontSize:'16px'}} disabled={isDisabled} onClick={onClick} type="button" className={`text-black ${isDisabled?'bg-gray-300':'bg-blue-700 hover:bg-blue-800 focus:ring-blue-300'} focus:outline-none focus:ring-4  font-small rounded-full text-xs px-1 py-2.5 text-center subText `}>{text}</button>
            
            case 'alert':
                return <button  disabled={isDisabled} onClick={onClick} type="button" className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-1 py-2.5 mr-2 subText">{text}</button>
            
            case 'caution':
                return <button disabled={isDisabled} onClick={onClick}  type="button" className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-1 py-2.5 mr-2 subText ">{text}</button>

            case 'success':
                return <button disabled={isDisabled} onClick={onClick}  type="button" className="focus:outline-none text-white bg-green-700 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-small rounded-lg text-xs px-1 py-2.5 mr-2 subText ">{text}</button>
    

                case 'backButton':
                    return   ( <button  disabled={isDisabled} onClick={onClick} className="inline-flex items-center h-10 px-5 text-black-800 transition-colors duration-150 bg-grey-400 rounded-lg focus:shadow-outline hover:bg-grey-400">
                                     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fillRule="currentColor">
                                      <path fillRule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                       </svg>
                                       <span>{text}</span>
                               </button>);
            case 'loading': 
                return (<button disabled type="button" className="py-2.5 px-1 mr-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 inline-flex items-center">
                            <svg className="inline mr-2 w-4 h-4 text-gray-200 fill-gray-600" version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="100%" height="100%" viewBox="0 0 1754.000000 1694.000000"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,1694.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M8400 16453 c-389 -26 -581 -46 -860 -89 -1543 -240 -3017 -952
                    -4165 -2011 -1421 -1313 -2307 -3037 -2540 -4943 -62 -511 -71 -1103 -24
                    -1605 203 -2183 1272 -4163 2989 -5534 1056 -845 2318 -1409 3655 -1635 1549
                    -262 3137 -48 4595 621 577 264 1179 637 1675 1038 432 349 871 788 1220 1220
                    401 496 774 1098 1038 1675 669 1458 883 3046 621 4595 -390 2307 -1773 4331
                    -3784 5537 -1122 673 -2339 1047 -3670 1128 -148 9 -635 11 -750 3z m761 -173
                    c1487 -74 2877 -550 4089 -1400 542 -381 1069 -868 1506 -1391 390 -469 758
                    -1039 1014 -1574 135 -281 150 -319 150 -385 l0 -60 -512 0 c-1032 0 -6121
                    -30 -6127 -36 -3 -3 -8 -1343 -9 -2978 l-4 -2973 879 -6 c483 -4 1735 -7 2783
                    -7 l1905 0 -3 482 c-2 266 -5 484 -7 486 -2 2 -282 -2 -622 -9 -820 -18 -2955
                    -19 -3258 -1 -277 16 -412 28 -547 48 l-108 16 -4 32 c-3 17 -8 347 -11 732
                    l-6 701 108 17 c286 43 621 56 1428 56 369 0 1000 -7 1403 -16 404 -8 736 -14
                    738 -11 5 5 -13 765 -22 942 l-6 110 -1779 -3 -1779 -3 0 731 0 730 2965 0
                    2964 0 10 -37 c106 -405 181 -859 223 -1348 18 -203 18 -1068 0 -1265 -76
                    -854 -240 -1559 -533 -2292 -573 -1436 -1586 -2688 -2878 -3557 -996 -669
                    -2142 -1100 -3331 -1251 -376 -47 -504 -54 -1020 -55 -508 0 -617 5 -970 46
                    -2302 267 -4374 1569 -5631 3539 -131 206 -254 423 -374 660 -163 324 -205
                    423 -205 482 l0 51 193 -7 c558 -21 1068 -27 2552 -32 1598 -6 2739 4 3031 26
                    273 21 490 94 691 232 207 142 379 386 436 618 81 327 88 1588 11 1858 -78
                    274 -388 591 -744 761 -153 73 -251 98 -463 118 -281 27 -1005 24 -1667 -7
                    -671 -32 -1588 -35 -1875 -7 -99 9 -197 20 -217 23 l-38 7 0 192 c0 105 3 428
                    7 717 l6 525 2471 7 c1359 4 2473 9 2475 11 3 4 -19 1008 -22 1012 -1 0 -1059
                    2 -2352 4 -2460 2 -2434 2 -2685 -42 -550 -96 -828 -400 -905 -987 -21 -161
                    -38 -728 -32 -1047 6 -272 20 -420 57 -585 72 -328 261 -569 570 -725 199
                    -100 431 -159 775 -197 172 -19 941 -16 1377 5 948 46 1654 43 2202 -9 l69 -7
                    -6 -386 c-4 -212 -10 -540 -14 -727 l-6 -341 -2261 -7 c-1244 -3 -2654 -9
                    -3133 -12 l-873 -6 -9 24 c-16 43 -90 373 -120 540 -156 849 -173 1755 -50
                    2613 184 1289 688 2505 1469 3543 455 606 1030 1173 1636 1615 1450 1057 3190
                    1568 5025 1477z"/>
                    </g>
                            </svg>
                            {text}
                        </button>
                        );
            
            case 'logoButton':
                return (
                    <button className='"inline-flex relative items-center text-sm font-medium text-center ' type="button" disabled={isDisabled} onClick={onClick} style={{color:'#342323'}}>
                       <img style={{display:'block', margin:'auto'}} width={"30px"} src={imageLogo} alt=""></img>
                       {notificationCount ? <div className="inline-flex absolute -top-2 right-4 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white #342323">{notificationCount}</div>: null}
                        <div>{text}</div>
                    </button>
                );


            case 'productButton':
                return (
                    <button className='"inline-flex relative items-center p-3 text-sm font-medium text-center ' type="button" disabled={isDisabled} onClick={onClick} style={{margin:'1em 1em 0em 1em', height:'74px',color:'#342323'}}>
                        <img style={{display:'block', margin:'auto'}} width={"50%"} src={imageLogo} alt=""></img>
                        {notificationCount ? <div className="inline-flex absolute -top-2 right-4 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white #342323">{notificationCount}</div>: null}
                        <div style={{fontSize:'2em', marginTop:'5px'}}>{text}</div>
                    </button>
                );
    
            case 'buyButton':
                return (
                    <button type="button" className="text-black-700 border border bg-orange-400 hover:bg-orange-400 text-black font-normal py-2 px-4 rounded-full">
                        <img style={{display:'block', margin:'auto'}} width={"50px"} src={imageLogo} alt=""></img>
                        <div style={{fontSize:'15px', marginTop:'5px'}}>{text}</div>

                    </button>
                );


    
            default:
                return  <button type="button" class="inline-block px-6 py-2 border-2 border-gray-800 text-gray-800 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">{text}</button>

    
    
        }
    }


  return (
    <>{buttonVaraiation()}</>
   )
}

export default Button