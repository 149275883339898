import React, {useState} from 'react';

import Button from '../buttons/Button';
// import { useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import { useSelector } from "react-redux";


const LeftNavbar = ({handleLogout}) => {
    const isAuth = useSelector((state)=> state.userReducer.isAuthenticated);

    const { t } = useTranslation();
    // const isAuth = useSelector((state)=> state.userReducer.isAuthenticated);
    const [toggleMenu, setToggleMenu] = useState(false);
    const [toggleDrawer, setToggleDrawer] = useState(true);
    const languages = [
        {
          code: 'hi-IN',
          name: 'हिन्दी',
          country_code: 'in'
      },
      {
        code: 'en',
        name: 'English',
        country_code: 'US'
      }]
  return (
    <>
    <div style={{padding:"2px", margin:"10px"}}>
    <div onClick={()=>{setToggleDrawer(!toggleDrawer)}}>
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
    </div>
       { <div onClick={()=>{setToggleDrawer(!toggleDrawer)}} style={{height:'100%',marginTop:'-40px', width:'104%',opacity: `${toggleDrawer ? '0%':'100%'}`, left: `${toggleDrawer ? '-100%':'0%'}`, transition: '0.3s ease-in-out'}} id="drawer-navigation" className={`fixed -m-2 z-40 h-screen p-4 overflow-y-auto bg-white w-80 `} tabIndex="-1" aria-labelledby="drawer-navigation-label">
            <h5 id="drawer-navigation-label" className="text-base font-semibold text-gray-500 uppercase ">Menu</h5>
            <button  onClick={()=>{setToggleDrawer(!toggleDrawer)}} type="button" data-drawer-dismiss="drawer-navigation" aria-controls="drawer-navigation" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center  " >
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Close menu</span>
            </button>
        <div className="overflow-y-auto py-4 px-3 bg-gray-50 rounded ">
            <ul className="space-y-2">
              {/* <li  >
                    <div href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                    <svg aria-hidden="true" className="w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                    <span className="ml-3">Dashboard</span>
                    </div>
                </li> */}
           <li>
                    <button onClick={(e)=>{setToggleMenu(!toggleMenu);e.stopPropagation()}}  type="button" className="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100  " aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                        <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900  " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd"></path></svg>
                        <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item="">Language</span>
                        <svg sidebar-toggle-item="" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                    <ul onClick={()=>{setToggleDrawer(!toggleDrawer)}}  id="dropdown-example" className={`${toggleMenu ?'': 'hidden'} py-2 space-y-2`}>
                        {languages.map(item => <li key={item.country_code}>
                      <div className="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100  " id = {item.name} onClick={()=>i18next.changeLanguage(item.code)}>{item.name}</div>
                    </li> )}
                    </ul>
                </li>
            {/* <li  onClick={()=>{setToggleDrawer(!toggleDrawer)}}>
                    <div href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                    <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">Notifications</span>
                    <span className="inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full">3</span>
                    </div>
                </li> */}
               {/* <li  >
                    <div href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                    <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">Users Profile</span>
                    </div>
                </li> */}
                
                <li>
                <NavLink to="/contact">
                     <button>
                    <div href="#" className="flex items-center p-1 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                            <div style={{marginLeft:'4px'}}>
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z"/></svg>                    
                    </div>
                    <span className="flex-1 ml-3 whitespace-nowrap">Contact Us</span>
                    </div>
                    </button>
                    </NavLink>
                    
                </li>
            <li>
               {isAuth? <NavLink to="/">
                    <div href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                    <Button text={t("logout")} type="alert" onClick={handleLogout} />
                    </div>
                </NavLink>: <NavLink to="/login">
                    <div href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                    <Button text={t("login")} type="alert" />
                    </div>
                </NavLink>}
                </li>
                <li>
               {isAuth?null: <NavLink to="/signup">
                    <div href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                    <Button text={t("SignUp")} type="alert" />
                    </div>
                </NavLink>}
                </li>
            </ul>
        </div>
        </div>}
        </div>
    </>

  )
}

export default LeftNavbar