import React, { useEffect, useState } from 'react'
import DataApi from '../apiCalls/dataApi'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setAggregate } from '../store/actions/UserActions';
import Button from '../components/buttons/Button';
import {addCommaToRupee} from '../utils/generalUtils';
import CustomSwipeButton from '../components/swipeButton/CustomSwipeButton';
import Select from 'react-select';
import { toast } from 'react-toastify';
import OrderSuccess from '../components/OrderSuccess';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

const AggregatorProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lot, setLot] = useState(1);
  const [placedOrder, setPlacedOrder] = useState(false);

  const [address, setAddress] = useState('');
  const [District, setDistrict] = useState('');
  const [pinCode, setPincode] = useState('');
    const {prod} = useParams();
    const {getAggregators, createOrder} = DataApi()
    useEffect(()=>{
      const getAggregateData = async() => {
       const resp = await getAggregators();
       if(resp && resp.length){
          dispatch(setAggregate(resp))
       }
      }
      getAggregateData()
      
      },[dispatch, getAggregators])
    const aggregatorData = useSelector(state=> state.userReducer.aggregate);
    const meObject = useSelector((state)=> state.userReducer.meObject);
    const selectedAggregateItem  = aggregatorData.find(items => items.id===Number(prod));
    const BlockDropdown = [{value:"Kanpur",label:"Kanpur"}, {value:"Lucknow",label:"Lucknow"}];
    ChartJS.register(ArcElement, Tooltip, Legend);
    const data = {
        labels: ['% Available', '% Purchased'],
        datasets: [
          {
            label: 'Used',
            data: [100-selectedAggregateItem?.attributes?.unitsSold*100/selectedAggregateItem?.attributes?.totalUnits, selectedAggregateItem?.attributes?.unitsSold*100/selectedAggregateItem?.attributes?.totalUnits],
            backgroundColor: [
                'rgba(0, 255, 0, 0.6)',
              'rgba(255, 0, 0, .6)',
            ],
            borderColor: [
                'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',

            ],
            borderWidth: 1,
          },
        ],
      };

    const createOrderHandler = async() => {
      const payload = {
            product: selectedAggregateItem?.attributes.product,
            subProduct: selectedAggregateItem?.attributes.subProduct,
            unitPrice: selectedAggregateItem?.attributes.unitPrice,
            units: lot*selectedAggregateItem?.attributes.lotSize,
            brand: selectedAggregateItem?.attributes.brand,
            dimension: selectedAggregateItem?.attributes.dimension,
            unit: selectedAggregateItem?.attributes.unit,
            deliveryAddress: {
              street : address,
              district: District,
              city: District,
              state: 'U.P.',
              pincode: pinCode
            },
            "users_permissions_user": meObject.id,
            "aggregator": prod,
            type: "aggregator"
      }
      const res = await createOrder(payload);
      if(res){
        toast.success('Order Created');
        setPlacedOrder(res);
      }else {
        toast.error('Order Failed')
      }
    }

  return (
    <div className='mb-32 mt-4'>
      <Button onClick={()=>navigate(`/aggregator/`, {replace: true}) } text="Back" type="backButton"></Button>
    {placedOrder?<OrderSuccess data={placedOrder}/>:<>
    <div>
    <Doughnut data={data} />
    </div>
        <div>{selectedAggregateItem?.attributes.brand} - {selectedAggregateItem?.attributes.product} - {selectedAggregateItem?.attributes.dimension}</div>
        <div>₹{selectedAggregateItem?.attributes.unitPrice} per {selectedAggregateItem?.attributes.unit}</div>
        {/* <div>Min Order {selectedAggregateItem?.attributes.lotSize}</div> */}
        <div className='flex m-0'>
                  <Button onClick={()=>lot-1 && setLot(lot-1)} text="-"></Button>
                  <div className='m-4'>{lot}</div>
                  <Button onClick={()=>setLot(lot+1)} text="+"></Button>
                  <div className='m-4'>X</div>
                  <div className='mt-4'>{selectedAggregateItem?.attributes.lotSize} {selectedAggregateItem?.attributes.unit}</div>
        </div>
        <div>
              <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900">Pincode</label>
              <div className='flex'>
                  <input  onChange={(e)=>setPincode(e.target.value)} type="number" id="first_name" className="mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="6 digits" required={true}/>
              </div>
          </div>
        <div>
              <label htmlFor="street-address" className="block mb-2 text-sm font-medium text-gray-900">Address</label>
              <input onChange={(e)=>{setAddress(e.target.value)}} value={address} type="text" id="street-address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required=""/>
        </div>
        <div>
              <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900">District</label>
              <Select isSearchable={false} onChange={(e)=>setDistrict(e.value)} options={BlockDropdown} />
        </div> 
        <div>
                  <div style={{fontWeight:'bold'}}>Order Summary </div>
                  <div>
                  Order Size : {lot * selectedAggregateItem?.attributes.lotSize} {selectedAggregateItem?.attributes.unit}
                  </div>
                  <div>
                  Order Price : ₹{addCommaToRupee(lot * selectedAggregateItem?.attributes.lotSize * selectedAggregateItem?.attributes.unitPrice)}
                  </div>
                  <div className='flow-root mt-2 place-items-center' >
            
                  <CustomSwipeButton disabled={!pinCode || !address || !District} onSuccess={()=>createOrderHandler()} text="Swipe to Place the Order" text_unlocked="Placing Order"></CustomSwipeButton>
                  </div>
        </div>

      </>}

      
    </div>
  )
}

export default AggregatorProduct