import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Button from '../components/buttons/Button';
import { addCommaToRupee} from '../utils/generalUtils';
import { useSelector } from 'react-redux';
import moment from 'moment';

const DetailedOrder = () => {
  const {orderId} = useParams();

  const orders = useSelector((state)=>state.userReducer.orders);

  const orderDetail = orders.find( item => item.id===Number(orderId))

  const totalPrice = Math.floor(orderDetail.attributes.unitPrice * orderDetail.attributes.units);
  return (
    <div>
        {orders.length && <>
         <NavLink to="/orders"> <Button text="back" type="backButton"></Button></NavLink>

        <div>DetailedOrder</div>
        <div>Order ID: {orderDetail.attributes.id}</div>
        <div>Status: {orderDetail.attributes.status}</div>
        <div>
        <span>Product: {orderDetail.attributes.manufacturer}</span>
        <span> - {orderDetail.attributes.product.toUpperCase()}</span>
        </div>

        <div>Quantity: {`${orderDetail.attributes.units} ${orderDetail.attributes.unit} @ ₹${orderDetail.attributes.unitPrice}`}</div>
        <div>Order Placed on: {moment(orderDetail.attributes.createdAt).format('MMMM Do YYYY, h:mm a')}</div>
        <div>Total price: {`@ ₹${addCommaToRupee(totalPrice)}/- (Inc. GST)`}</div>
       </>
      }
    </div>
  )
}

export default DetailedOrder