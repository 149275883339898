import React, {useEffect, useMemo, useState} from 'react';
import DataApi from "../apiCalls/dataApi";
import OrderCard from '../components/cards/Card';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, setOrders} from '../store/actions/UserActions';
import Button from '../components/buttons/Button';
import {NavLink} from 'react-router-dom';
import { useTranslation } from "react-i18next";

function MyOrder() {
    const { t } = useTranslation();
    const {getMyOrder} = DataApi();
    const dispatch = useDispatch();
    const orders = useSelector((state)=>state.userReducer.orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(()=>{
        const fetchOrders = async() =>{
          dispatch(setIsLoading(50));
            const data  = await getMyOrder();
            dispatch(setOrders(data.data))
            dispatch(setIsLoading(false));
        }
        !orders.length && fetchOrders();
    },[dispatch, getMyOrder, orders.length]);

console.log(orders)
  return (
    <>
    {!orders.length ? <>
      <div>There are no order placed from this account. To place orders go to Home page</div>
      <NavLink to="/"><Button text={t("home")}></Button></NavLink>
      
      </>:null}
      <div style={{top:'35px', width:'100%', background:'white'}}>
        <div style={{margin:'65px 0px 60px 0px'}}>
        {orders.map((order, index) => {
            return (
              <NavLink key={order.id} to={`/orders/${order.id}`}> 
                  <OrderCard 
                      manufacturer={order.attributes.brand}
                      product={order.attributes.product}
                      status={order.attributes.status} 
                      quantity={order.attributes.units}
                      unitPrice={order.attributes.unitPrice}
                      orderID={order.id}
                      >
                  </OrderCard>
                    </NavLink>) 
        })}
        </div>
        </div>
      </>

  )
}

export default MyOrder