import React, {useEffect} from "react";
import { Provider } from 'react-redux';
import store from './store'
import Home from "./pages/Home";
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA4 from "react-ga4";
import BaseUrl from './config';
import { ToastContainer } from 'react-toastify';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui) 
    supportedLngs: ["en", "hi-IN"],
    // lng: "en", // if you're using a language detector, do not define the lng option
    // lng: document.querySelector('html').lang,
    fallbackLng: "en",
    detection: {
      order: ['cookie','htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {useSuspense: false}
  });

  BaseUrl[process.env.REACT_APP_NODE_ENVIRONMENT].ga4Id && ReactGA4.initialize(BaseUrl[process.env.REACT_APP_NODE_ENVIRONMENT].ga4Id);


  export default  function App() {

    useEffect(()=>{
      console.log('firing app load', window.location.pathname)
      ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
      },[])
  return (
    <div className="App">
       <Provider store={store}>
       <ToastContainer
             pauseOnFocusLoss={false}
             autoClose={5000}
             hideProgressBar
             newestOnTop={false}
             closeOnClick
             rtl={false}
             draggable
             pauseOnHover
             limit={1}
           />
          <Home></Home>
     </Provider>
    </div>
  );
}
