import React, {useState} from "react";
import AuthApi from "../apiCalls/authApi.js";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated, setToken, setMeObject, setIsLoading } from '../store/actions/UserActions'
import { useTranslation } from "react-i18next";
import { toast} from 'react-toastify';
import Button from "../components/buttons/Button.js"
import {useNavigate } from "react-router-dom";


const LoginPage = () => {
  const {logIn, getMeObject, verifyOtp} = AuthApi();
  const { t } = useTranslation();
  const [number, setNumber]= useState('');
  const [enterOtp, setEnterOtp] = useState(false);
  const [otp, setOtp] = useState('')
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector((state)=> state.userReducer.isAuthenticated)
  const isVerified = useSelector((state)=> state.userReducer.isVerified)


  const handleSendOtp = async() =>{
    dispatch(setIsLoading(50));
    setIsAuthenticating(true);
    if(number.toString().length!==10){
      toast.error('Enter Valid number');
      dispatch(setIsLoading(false));
      setIsAuthenticating(false);
      return
    }
    const res = await logIn({phoneNumber: number});
    if(res && res.data.status===200){
      dispatch(setIsLoading(false));
      setIsAuthenticating(false);
      setEnterOtp(true);
      
    }else{
      dispatch(setIsLoading(false));
      toast.error('Mobile Number not found');
      setIsAuthenticating(false);
    }
  }

  const handleLogin = async() => {
    dispatch(setIsLoading(50));
    setIsAuthenticating(true);
    const res = await verifyOtp({phoneNumber: number, otp: otp});
    if(res && res.status===200 && res.data.jwt && res.data.user.access){
      localStorage.setItem('token',res.data.jwt);
      localStorage.setItem('user',res.data.user.username);
      const meObject = await getMeObject({token:res.data.jwt});
      if(meObject){
        dispatch(setMeObject(meObject));
      }
      dispatch(setIsAuthenticated(res.data.user.username));
      dispatch(setToken(res.data.jwt));
      setIsAuthenticating(false);
      dispatch(setIsLoading(false));
    } else {
      setIsAuthenticating(false);
      dispatch(setIsLoading(false));
      toast.error('Invalid OTP')
    }
  }

    if(isAuth){
      navigate('/', {replace: true})
    }

    const getOtpScreen = () => (
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {isVerified?null:<div className="block text-gray-700 text-sm font-bold mb-4">Verify Mobile and Login</div>}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
        Whatsapp Number
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="username"
          type="number"
          value={number}
          placeholder={`Enter 10 digit Whatsapp number`}
          onChange={(e)=> setNumber(e.target.value)}/>
      </div>

      <div className="flex items-center justify-between">
      {!isAuthenticating ? <button className="bg-green-400 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="loading"
        onClick={handleSendOtp}>
          {t('getOtp')}
        </button> : 
        <Button text="Authenticating" type="loading"></Button>}
      </div>
    </div>
    );

    const enterOtpScreen = () => (
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="otp">
        {'OTP sent to your mobile number '+number }
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="otp"
          type="number"
          placeholder="Enter 6 digit OTP"
          value={otp}
          onChange={(e)=> setOtp(e.target.value)}/>
      </div>

      <div className="flex items-center justify-between">
      {!isAuthenticating ? <button className="bg-green-400 hover:bg-green-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="loading"
        onClick={handleLogin}>
          {t('verifyOtp')}
        </button> : 
        <Button text="Authenticating" type="loading"></Button>}

        {<button className="bg-yellow-400 hover:bg-yellow-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="loading"
        onClick={()=>setEnterOtp(false)}>
          {t('reEnterMobile')}
        </button>}
      </div>
    </div>
    );

    return (
    <div className="w-full">
    {enterOtp?enterOtpScreen():getOtpScreen()}
      <p className="text-center text-gray-500 text-xs">
        &copy;2023 Powered by Nirman Easy. All rights reserved.
      </p>
    </div>
    );
};

export default LoginPage;