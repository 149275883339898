import axios from "axios";
import BaseURL from "../config";
import { setIsLoading } from "../store/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { useCallback } from "react";

const DataApi = () =>{
    const dispatch = useDispatch();
    const token = useSelector((state)=>state.userReducer.jwtToken);
    const getInventory = useCallback(async() => {
        dispatch(setIsLoading(50));
        try {
                const response = await axios.get(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/inventories?populate=*`, 
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`
                    }
                });
                if(response && response.status===200){
                    dispatch(setIsLoading(false));
                    return response.data.data
                } else{
                    dispatch(setIsLoading(false));
                    return false
                }
            }
        catch(err){
            dispatch(setIsLoading(false));
        return []
        }
    },[dispatch, token])

    const getAggregators = useCallback(async() => {
        dispatch(setIsLoading(50));
        try {
                const response = await axios.get(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/aggregators?populate=*`, 
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`
                    }
                });
                if(response && response.status===200){
                    dispatch(setIsLoading(false));
                    return response.data.data
                } else{
                    dispatch(setIsLoading(false));
                    return false
                }
            }
        catch(err){
            dispatch(setIsLoading(false));
        return []
        }
    },[dispatch, token])

    const getMyOrder = useCallback(async()=> {
        try{
            if(token){
            const response = await axios.get(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/orders/?populate=*&&sort[0]=createdAt%3Adesc`,
            {
                headers: { 
                    'Authorization': `Bearer ${token}`
                  }
            });
            return response.data;
        } else {
            return;
        }
        }catch (exc){
            console.log(exc)
        }
    },[token])

    const createOrder = useCallback(async(body) => {
        dispatch(setIsLoading(50));
        const payload = {
            data:body
        }
        try {
                const response = await axios.post(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/orders?populate=*`, payload,
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`
                    }
                });
                if(response && response.status===200){
                    dispatch(setIsLoading(false));
                    return response.data.data
                } else{
                    dispatch(setIsLoading(false));
                    return false
                }
            }
        catch(err){
            dispatch(setIsLoading(false));
        return []
        }
    },[dispatch, token])

    const deleteMediaFile = async(id) => {
        dispatch(setIsLoading(50));
        try {
                const response = await axios.delete(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/upload/files/${id}`, 
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`
                    }
                });
                if(response && response.status===200){
                    dispatch(setIsLoading(false));
                    return true
                } else{
                    dispatch(setIsLoading(false));
                    return false
                }
            }
        catch(err){
            dispatch(setIsLoading(false));
        return []
        }
    }

    const updateVehicleMedia = async({formData}) => {
        try{

            const resp= await axios.post(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/upload`,formData,
            {
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
            return resp;
        }catch(exception){
            // toast.error(exception.response?.data?.message || 'Something went wrong')
            console.log(exception)
            return false
        }
            };

    const updatePortfolioData = async(payload, id) => {      
        try{

            const resp= await axios.put(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/portfolios/${id}`,payload,
            {
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
            toast.success('Updated')
            return resp;
        }catch(exception){
            toast.error('Failed');
            console.log(exception)
            return false
        }
    }



    return {
        updateVehicleMedia,
        deleteMediaFile,
        getInventory,
        createOrder,
        getAggregators,
        updatePortfolioData,
        getMyOrder
    }
}

export default DataApi;