export const enviroment_type = {
    development: {
      authServer: "http://localhost:1337/api",
      postOffice: "https://api.postalpincode.in/pincode",
      mediaServer: "http://localhost:1337"
    },
    production: {
      authServer: "https://api.nirmaneasy.in/api",
      postOffice: "https://api.postalpincode.in/pincode",
      mediaServer: "",
      ga4Id:'G-79M6GYMGVZ',
    },
  };
  
  export default enviroment_type;
  