export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_TOKEN = "SET_TOKEN";
export const SET_ME_OBEJCT = "SET_ME_OBEJCT";
export const SET_LOADING = "SET_LOADING";
export const RESET_DATA = "RESET_DATA";
export const TOGGLE_FULLSCREEN = "TOGGLE_FULLSCREEN";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const SET_IS_VERIFIED = "SET_IS_VERIFIED";
export const SET_INVENTORIES = "SET_INVENTORIES";
export const SET_AGGREGATE = "SET_AGGREGATE";
export const SET_ORDERS = "SET_ORDERS"
