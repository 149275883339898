import {
    SET_IS_AUTHENTICATED,
    SET_TOKEN,
    SET_ME_OBEJCT,
    SET_LOADING,
    RESET_DATA,
    TOGGLE_FULLSCREEN,
    UPDATE_LOCATION,
    SET_IS_VERIFIED,
    SET_INVENTORIES,
    SET_AGGREGATE,
    SET_ORDERS
} from '../types/UserTypes'

const INITIAL_STATE = {
    isAuthenticated:false,
    jwtToken: '',
    meObject: '',
    isLoading: false,
    showNavbar: true,
    isFullScreen: false,
    selectedLocation: '',
    isVerified:true,
    inventories: [],
    aggregate: [],
    orders: [],
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case RESET_DATA:
        {
            return {...state, isAuthenticated:false, jwtToken: '',
            meObject: '',
            isLoading: false,isVerified:true};
        }

        case SET_IS_AUTHENTICATED:
            {
                const isAuthenticated = action.payload;
                return {...state, isAuthenticated:isAuthenticated};
            }

        case TOGGLE_FULLSCREEN:
            {
                const fullScreenStatus = action.payload;
                return {...state, isFullScreen:fullScreenStatus};
            }

        case UPDATE_LOCATION:
            {
                const location = action.payload;
                return {...state, selectedLocation:location};
            }

        case SET_TOKEN:
            {
                const token = action.payload;
                return {...state, jwtToken:token};
            }


        case SET_ME_OBEJCT:
            {
                const meObject = action.payload;
                return {...state, meObject:meObject}
            }
        
        case SET_LOADING:
            {
                const isLoading = action.payload;
                return {...state, isLoading:isLoading}
            }

        case SET_IS_VERIFIED:
            {
                const isVerified = action.payload;
                return {...state, isVerified:isVerified}
            }
        
        case SET_INVENTORIES:
            {
                const inventories = action.payload;
                return {...state, inventories:inventories}
            }

        case SET_AGGREGATE:
            {
                const aggregate = action.payload;
                return {...state, aggregate:aggregate}
            }

        case SET_ORDERS:
            {
                const orders = action.payload;
                return {...state, orders:orders}
            }

        default:
            return state;
            }
          };
        
          export default reducer;    