import React, { useEffect } from 'react'
import DataApi from '../apiCalls/dataApi'
import ProductCard from '../components/cards/ProductCard';
import { setInventories } from '../store/actions/UserActions';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';


const UserHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inventories = useSelector(state => state.userReducer.inventories)
  const {getInventory} = DataApi()
  useEffect(()=>{
    const getInventoryData = async() => {
      const resp = await getInventory();
      if(resp && resp.length){
        dispatch(setInventories(resp));
      }
    }
  getInventoryData();

  },[dispatch, getInventory])





  return (
    <div style={{marginTop:'3em'}}>
<div style={{fontWeight:800, fontSize:'1.5em'}}>Products</div>
    {inventories.length ?inventories.map(product=> {
      return (
        <ProductCard key={product.id} onClick={()=>navigate(`/product/${product.id}`, {replace: false}) } productObject={product.attributes}/>
      )
      }):null}
   
    </div>
  )
}

export default UserHome