import React, {useEffect, useState} from 'react';
import Button from '../components/buttons/Button';
import Select from 'react-select';
import {addCommaToRupee} from '../utils/generalUtils';
import { useDispatch, useSelector } from "react-redux";
import {setInventories} from '../store/actions/UserActions';
import OrderAPI from '../apiCalls/dataApi';
import { toast } from "react-toastify";
import CustomSwipeButton from '../components/swipeButton/CustomSwipeButton';
import { useNavigate, useParams } from 'react-router';
import OrderSuccess from '../components/OrderSuccess';

function PrepareOrder() {
    const {createOrder, getInventory} = OrderAPI();
    const navigate = useNavigate()
    const [address, setAddress] = useState('');
    const [District, setDistrict] = useState('');
    const [pinCode, setPincode] = useState('');
    const [placedOrder, setPlacedOrder] = useState(false);
    const inventories = useSelector(state => state.userReducer.inventories);
    const meObject = useSelector((state)=> state.userReducer.meObject);

    const {itemId} = useParams();
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(25);
    const productToBeOrdered = inventories.filter(item => item.id===Number(itemId));
    console.log(placedOrder)
    const showNavbar = useSelector((state)=> state.userReducer.showNavbar);
    const BlockDropdown = [{value:"Kanpur",label:"Kanpur"}, {value:"Lucknow",label:"Lucknow"}];

    const handleOrderCreation = async() => {
        const payload = {
              product: productToBeOrdered[0]?.attributes.product,
              subProduct: productToBeOrdered[0]?.attributes.subProduct,
              unitPrice: productToBeOrdered[0]?.attributes.unitPrice,
              units: quantity,
              brand: productToBeOrdered[0]?.attributes.brand,
              dimension: productToBeOrdered[0]?.attributes.dimension,
              unit: productToBeOrdered[0]?.attributes.unit,
              deliveryAddress: {
                street : address,
                district: District,
                city: District,
                state: 'U.P.',
                pincode: pinCode
              },
              "users_permissions_user": meObject.id,
              type: "retail"
        }
        const res = await createOrder(payload);
        if(res){
          toast.success('Order Created');
          setPlacedOrder(res);
        }else {
          toast.error('Order Failed')
        }
      }
    useEffect(()=>{
        const getInventoryData = async() => {
          const resp = await getInventory();
          if(resp && resp.length){
            dispatch(setInventories(resp));
          }
        }
      getInventoryData();
    
      },[dispatch, getInventory])



    useEffect(()=>{
        // showNavbar && dispatch(setShowNavbar(false))
      },[dispatch, showNavbar])

  return (
    <>
    {
     !placedOrder &&  <div>
    <Button onClick={()=>navigate(`/`, {replace: false})} type="backButton" text="back"></Button>
    <div>PrepareOrder</div>
    <div>{`Brand: ${productToBeOrdered[0]?.attributes?.brand}`}</div>
    <div>{`Product: ${productToBeOrdered[0]?.attributes?.subProduct} - ${productToBeOrdered[0]?.attributes?.product}`}</div>
   <div>{`Unit Price: ₹${addCommaToRupee(productToBeOrdered[0]?.attributes?.unitPrice)}`}</div>
   <div style={{margin: 'auto'}}>Quantity(in {productToBeOrdered[0]?.attributes?.unit}):  </div>
    <div className='flex'>
        <div style={{marginRight:'1em'}}>
            <Button onClick={()=>quantity-25 && setQuantity(Math.max(quantity-20, 25))} text="-20"></Button>
            <Button onClick={()=>quantity-25 && setQuantity(Math.max(quantity-5, 25))} text="-5"></Button>
        </div>
            <input style={{width:'5em'}} type={"number"} onChange={(e)=>setQuantity(e.target.value)} disabled={true} onBlur={(e)=>setQuantity(Math.max(25, e.target.value))} min={"25"} value={quantity} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></input>
        <div style={{marginLeft:'1em'}}>
            <Button onClick={()=> setQuantity(Math.min(quantity+5))} text="+5"></Button>
            <Button onClick={()=> setQuantity(Math.min(quantity+20))} text="+20"></Button>
        </div>
    </div>
   {<div className='mt-5'>{`Total ex-price: ₹${addCommaToRupee(quantity*productToBeOrdered[0]?.attributes?.unitPrice)}`}</div>}
   <div>
              <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900">Pincode</label>
              <div className='flex'>
                  <input  onChange={(e)=>setPincode(e.target.value)} type="number" id="first_name" className="mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="6 digits" required={true}/>
              </div>
          </div>
        <div>
              <label htmlFor="street-address" className="block mb-2 text-sm font-medium text-gray-900">Address</label>
              <input onChange={(e)=>{setAddress(e.target.value)}} value={address} type="text" id="street-address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required=""/>
        </div>
        <div className='mb-32'>
              <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900">District</label>
              <Select isSearchable={false} onChange={(e)=>setDistrict(e.value)} options={BlockDropdown} />
        </div> 
    {<CustomSwipeButton disabled={!pinCode || !address || !District} onSuccess={handleOrderCreation} text="Swipe to Place the Order" text_unlocked="Placing Order"></CustomSwipeButton> }
    {<div className='flow-root mt-2 place-items-center' >
          
          </div>}
    </div>
    }
    {placedOrder && <OrderSuccess data={placedOrder}></OrderSuccess>}
    </>
  )
}

export default PrepareOrder