export const convertArrayToDropdownOptions = (input) => {
    const options = input.map(text => {return {value:text , label: text}});
    return options;
}

export const blockToSelectOption = (arr) => {
    const extractBlock = arr.map(element => element.Name);
    const dropDownMenu = convertArrayToDropdownOptions(extractBlock);
    return dropDownMenu
}

export const getProductsFromManufacturer = (data) => {
    const products = new Set();
    data.forEach(element => {
        products.add(element?.product);
    })
return [...products];
}

export const getManufacturersSellingItem = ({serverData, product}) => {
    const manufacturers = serverData.filter(item=>item.product === product)
return [...manufacturers];
}

export const convertManufacturerList = ({data}) => {
    const manufacturerList = data.map(item => item.attributes.Name);
    return manufacturerList;
}

const reverseString = (data) => {
    const stringData = String(data)
    const splitString = stringData.split("");
    const reverseArray = splitString.reverse();
    const joinedReverse = reverseArray.join("");
    return joinedReverse;
}

export const addCommaToRupee = (data) => {

    const originalString = reverseString(Math.floor(data));
    let rupeeString = reverseString(Math.floor(data));
    if(originalString.length > 3){
        rupeeString = rupeeString.slice(0, 3) + "," + rupeeString.slice(3);
    }
    if(originalString.length > 5){
        rupeeString = rupeeString.slice(0, 6) + "," + rupeeString.slice(6);
    }
    if(originalString.length > 7){
        rupeeString = rupeeString.slice(0, 9) + "," + rupeeString.slice(9);
    }
    if(originalString.length > 9){
        rupeeString = rupeeString.slice(0, 12) + "," + rupeeString.slice(12);
    }
    if(originalString.length > 11){
        rupeeString = rupeeString.slice(0,15) + "," + rupeeString.slice(15);
    }
    if(originalString.length > 13){
        rupeeString = rupeeString.slice(0,18) + "," + rupeeString.slice(18);
    }
    return reverseString(rupeeString)
}