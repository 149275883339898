import React from 'react'
import Button from './buttons/Button';
import {NavLink} from 'react-router-dom';
import { useTranslation } from "react-i18next";

function OrderSuccess({data}) {
  debugger;
    const { t } = useTranslation();
  return (
    <>
        <div>{`Your order is Successfully created with ID: ${data.id}`}</div>
        <div>Summary:</div>
        <div>{`${data.attributes.units} ${data.attributes.unit} @ ₹${data.attributes.unitPrice}`}</div>
        <div>{`Manufacturer: ${data.attributes.product}`}</div>
        <div>{`Product: ${data.attributes.dimension}`}</div>
        <div>{`Delivery address- `}</div>
       {<div>{`${data.attributes.deliveryAddress.street}-${data.attributes.deliveryAddress.district}-${data.attributes.deliveryAddress.pincode}`}</div>}
        <NavLink to="/orders"><Button text={t("orders")}></Button></NavLink>
    </>
  )
}

export default OrderSuccess