import React from 'react'
import BaseURL from "../../config";
const AggProductCard = ({productObject, onClick}) => {
    const { isActive, coverMedia, brand='Not Available', dimension='Not Available', product='Not Available', unit='Not Available', unitPrice='Not Available', totalUnits, unitsSold} = productObject

    if(!isActive){
      return null;
    }

    return (
      <div onClick={onClick}  class="container flex justify-center py-1">
        
        <div class="p-3 bg-white rounded-xl  hover:shadow w-full">


          <div class="flex justify-between w-full">

            <img src={`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].mediaServer}${coverMedia.data.attributes.url}`} style={{maxWidth: "200px", maxHeight: "150px", objectFit: "contain"}} alt='profile' class="rounded-lg"/>

            <div class="ml-2">

              <div class="p-1">
                
                <h3 class="text-xl">{product}</h3>
              <span>{dimension}</span>
              </div>

              <div class="flex justify-around items-center bg-gray-200 rounded-lg">

                <div class="mr-1">
                  <span class="text-gray-400 block">/{unit}</span>
                  <span class="font-bold text-black text-xl">₹{unitPrice}</span>  
                </div>

                <div class="mr-1">
                  <span class="text-gray-400 block">Brand</span>
                  <span class="font-bold text-black text-xl">{brand}</span>  
                </div>
              </div>  
              <div>
                {100-(unitsSold*100/totalUnits).toFixed(0)+ '% left' }
                </div>        
            </div>        
          </div>         
        </div>

      </div>
    )
}

export default AggProductCard