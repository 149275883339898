import React from 'react';

function OrderCard(props) {
    const product = props.product;
    const manufacturer  = props.manufacturer;
    const quantity = props.quantity;
    const unitPrice = props.unitPrice;
    const orderID = props.orderID;
    const status = props.status;
  return (
            <div className="block p-2 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100   ">
                {status==='pending'?null:<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{`Status: ${status}`}</h5>}
                <p className="font-normal text-gray-700 ">{`Order ID: ${orderID}`}</p>
                
                <p className="font-normal text-gray-700 ">{`${manufacturer?.toUpperCase()}-${product?.toUpperCase()}`}</p>
                <p className="font-normal text-gray-700 ">{`${quantity} Ton @  ₹${unitPrice}/-`}</p>
            </div>
  )
}

export default OrderCard;