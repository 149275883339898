import * as types from "../types/UserTypes";


  export const setIsAuthenticated = (payload) => ({
      type: types.SET_IS_AUTHENTICATED,
      payload,
    });

  export const setToken = (payload) => ({
      type: types.SET_TOKEN,
      payload,
    });

  export const setMeObject = (payload) => ({
      type: types.SET_ME_OBEJCT,
      payload,
    });

    export const setIsLoading = (payload) => ({
      type: types.SET_LOADING,
      payload,
    });


    export const updateLocation = (payload) => ({
      type: types.UPDATE_LOCATION,
      payload,
    });

    export const resetUserData = () => ({
      type: types.RESET_DATA,
      
    });

    export const setOrders = (payload) => ({
      type: types.SET_ORDERS,
      payload
    })


    export const toggleFullScreen = (payload) => ({
      type: types.TOGGLE_FULLSCREEN,
      payload,
    });

    export const setIsVerified = (payload) => ({
      type: types.SET_IS_VERIFIED,
      payload
    })

    export const setInventories = (payload) => ({
      type: types.SET_INVENTORIES,
      payload
    });

    export const setAggregate = (payload) => ({
      type: types.SET_AGGREGATE,
      payload
    });



  