import React from 'react'
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import aggregate from '../../assets/svg/aggregate.svg';
function Navbar() {
  const { t } = useTranslation();
  return (
    // <div className="w-full h-screen">
	<section style={{zIndex:'12'}} id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-12 bg-white shadow ">
		<div id="tabs" className="flex justify-between">
			<NavLink to="/" className="w-full focus:text-orange-500 hover:text-orange-500 justify-center inline-block text-center pt-2 pb-1">
				<div><svg width="25" height="25" viewBox="0 0 42 42" className="inline-block mb-1">
			            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			                    <path d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z" fill="currentColor" fillOpacity="0.1"></path>
			                    <path d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z" fill="currentColor"></path>
			                    <path d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z" stroke="currentColor" strokeWidth="2" fill="currentColor" fillOpacity="0.1"></path>
			                    <rect fill="currentColor" transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) " x="17" y="10.3137085" width="30" height="2" rx="1"></rect>
			                    <rect fill="currentColor" transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) " x="-3" y="10.3137085" width="30" height="2" rx="1"></rect>
			            </g>
				</svg></div>
				<span className="tab tab-home block text-xs">{t('home')}</span>
			</NavLink>
			<NavLink to="/aggregator" className="w-full focus:text-orange-500 hover:text-orange-500 justify-center inline-block text-center pt-2 pb-1">
			<div><img style={{margin:'auto'}} src={aggregate} alt=''></img></div>
				<span className="tab tab-home block text-xs">{t('aggregator')}</span>
			</NavLink>
			<NavLink style={{display:'grid'}} to="/orders" className="w-full focus:text-orange-500 hover:text-orange-500 justify-center inline-block text-center pt-2 pb-1">
				<div >
				<svg fill="#000000" width="25" height="25" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.992 2c3.396 0 6.998 2.86 6.998 4.995v4.997c0 1.924-0.8 5.604-2.945 7.293-0.547 0.43-0.831 1.115-0.749 1.807 0.082 0.692 0.518 1.291 1.151 1.582l8.703 4.127c0.068 0.031 0.834 0.16 0.834 1.23l0.001 1.952-27.984 0.002v-2.029c0-0.795 0.596-1.045 0.835-1.154l8.782-4.145c0.63-0.289 1.065-0.885 1.149-1.573s-0.193-1.37-0.733-1.803c-2.078-1.668-3.046-5.335-3.046-7.287v-4.997c0.001-2.089 3.638-4.995 7.004-4.995zM15.992-0c-4.416 0-9.004 3.686-9.004 6.996v4.997c0 2.184 0.997 6.601 3.793 8.847l-8.783 4.145s-1.998 0.89-1.998 1.999v3.001c0 1.105 0.895 1.999 1.998 1.999h27.986c1.105 0 1.999-0.895 1.999-1.999v-3.001c0-1.175-1.999-1.999-1.999-1.999l-8.703-4.127c2.77-2.18 3.708-6.464 3.708-8.865v-4.997c0-3.31-4.582-6.995-8.998-6.995v0z"></path>
</svg>
			</div><span style={{paddingTop:'4px'}} className="tab tab-home block text-xs">{t('orders')}</span>
			</NavLink>
		</div>
	</section>
  )
}
export default Navbar
