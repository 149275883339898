import React, {useState} from 'react';
import Select from 'react-select';
import AuthApi from '../apiCalls/authApi';
import { useDispatch } from "react-redux";
import { setIsAuthenticated, setToken, setMeObject, setIsLoading,
  setIsVerified } from '../store/actions/UserActions'
import { toast} from 'react-toastify';
import {useNavigate } from "react-router-dom";

const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {createTentativeAccount, verifyOtp, getMeObject} = AuthApi();
    const [whatsAppNumber, setWhatsAppNumber] = useState('');
    const [otpRequested, setOtpRequested] = useState(false);
    const [otp, setotp]= useState('');
    const [name, setName] = useState('')

    const createTentativeAccountHandler = async() => {
        if(!name){
            toast.error('Name missing');
            return
        }
        if(whatsAppNumber?.toString()?.length!==10){
            toast.error('Invalid Mobile number');
            return
        }
        setOtpRequested(true);
        const body = {
            userName:name,
            mobile:whatsAppNumber,
        }
        dispatch(setIsLoading(true));
        const result = await createTentativeAccount({payload:body})
        if(result.message==='Mobile number not Verified'){
          dispatch(setIsLoading(false));
          dispatch(setIsVerified(false));
          navigate('/login', {replace: true})
        }
        if(result.status!==200){
            toast.error(result.message||'Account not created');
            setOtpRequested(false)
            dispatch(setIsLoading(false));
            return
        }
        dispatch(setIsLoading(false));
    }

    const requestConfirmation = async() => {
        // confirm account
        if(otp.toString().length!==6){
            toast.error('OTP Not Valid');
            return
        }
        dispatch(setIsLoading(true));
        const res = await verifyOtp({phoneNumber: whatsAppNumber, otp: otp});
        if(res && res.status===200 && res.data.jwt && res.data.user.access){
            localStorage.setItem('token',res.data.jwt);
            localStorage.setItem('user',res.data.user.username);
            const meObject = await getMeObject({token:res.data.jwt});
            if(meObject){
              dispatch(setMeObject(meObject));
            }
            dispatch(setIsAuthenticated(res.data.user.username));
            dispatch(setToken(res.data.jwt));
            dispatch(setIsLoading(false));
            navigate('/', {replace: true})
          } else {
            dispatch(setIsLoading(false));
            toast.error('Invalid OTP')
          }
    }

  return (
    <div style={{marginTop:'2em'}}>
        <div class="p-1 bg-gray-100 flex items-center justify-center">
  <div class="container max-w-screen-lg mx-auto">
      <div class="bg-white rounded shadow-lg p-1 px-1 md:p-8 mb-6">
        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
          <div class="text-gray-600">
            <p class="font-medium text-lg">Personal Details</p>
            <p>Please fill out all the fields.</p>
          </div>

          <div class="lg:col-span-2">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
              <div class="md:col-span-5">
                <label for="full_name">Name</label>
                <input disabled={otpRequested} onChange={(e)=>setName(e.target.value)} type="text" name="full_name" id="full_name" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={name} />
              </div>
              <div class="md:col-span-5">
                <label for="number">WhatsApp number</label>
                <input disabled={otpRequested} onChange={(e)=>setWhatsAppNumber(Number(e.target.value) ||'')} type="number" name="number" id="number" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={whatsAppNumber} placeholder="9876543210" />
              </div>
             {otpRequested?<div class="md:col-span-5">
                <label for="number">OTP</label>
                <input onChange={(e)=>setotp(Number(e.target.value) ||'')} type="number" name="number" id="number" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={otp} placeholder="Enter 6 Digit OTP" />
              </div> :<div class="md:col-span-5 text-right">
                <div class="inline-flex items-end">
                  <button onClick={createTentativeAccountHandler} class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Get OTP</button>
                </div>
              </div>}
      
              {otpRequested?<div class="md:col-span-5 text-right">
                <div class="inline-flex items-end">
                  <button onClick={requestConfirmation} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                </div>
              </div>:null}

            </div>
          </div>
        </div>
      </div>

  </div>
</div>
    </div>
  )
}

export default SignUp