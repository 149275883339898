import axios from "axios";
import BaseURL from "../config";
import {toast} from 'react-toastify'

const AuthApi = () =>{
    const logIn = async({identifier='temp', password='pwd', phoneNumber}) => {
        try{
            const data = {
                "identifier": identifier,
                "password": password,
                "phoneNumber": phoneNumber
              };
          
            const resp = await axios.post(
                        `${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/auth/local`, data,
                        {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        }
                    )
            return resp;
        } catch (exception){
            return false;
        }
    }
    const getMeObject = async({token}) => {
        try{
            const resp = await axios.get(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/users/me?populate=*`,
            {
                headers: { 
                    'Authorization': `Bearer ${token}`
                  }
            });
            return resp.data
        }catch(exception){
            console.log(exception)
            return false;
        }
    }

    const createTentativeAccount = async({payload}) => {
        const data = payload
        try{
            const resp = await axios.post(`${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/users-permissions/users/createtempaccount`,data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                });
            return resp.data

        }catch(exception){
            console.log(exception)
            return false;
        }
    }

    const verifyOtp = async({phoneNumber, otp}) => {
        try{
            const data = {
                "phoneNumber": phoneNumber,
                "otp": Number(otp)
            }
            const resp = await axios.post(
                `${BaseURL[process.env.REACT_APP_NODE_ENVIRONMENT].authServer}/users-permissions/auth/verifyotp`, data,
                {
                headers: {
                    "Content-Type": "application/json",
                },
                }
            )
            return resp;
        } catch (exc){
            toast.error('OTP not valid.')
        }
    }


    return {
        logIn,
        getMeObject,
        verifyOtp,
        createTentativeAccount,
    }
}

export default AuthApi;