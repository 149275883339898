import React, { useEffect } from 'react'
import DataApi from '../apiCalls/dataApi'
import AggProductCard from '../components/cards/AggProductCard';
import { useSelector, useDispatch } from "react-redux";
import { setAggregate } from '../store/actions/UserActions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const Aggregator = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const aggregatorData = useSelector(state=> state.userReducer.aggregate)
    const {getAggregators} = DataApi()
    useEffect(()=>{
    const getAggregateData = async() => {
     const resp = await getAggregators();
     if(resp?.length){
        dispatch(setAggregate(resp))
     }
    }
    getAggregateData()
    
    },[dispatch, getAggregators])
    
  return (
    <div style={{marginTop:'2em'}}>
      <div style={{fontWeight:800, fontSize:'1.5em'}}>{t('aggregator')}</div>
    {aggregatorData.length ?aggregatorData.map(product=> {
      return (
        <AggProductCard key={product.id} onClick={()=>navigate(`/aggregator/${product.id}`, {replace: true}) } productObject={product.attributes}/>
      )
      }):null}
    </div>
  )
}

export default Aggregator